import logo from '../images/sand_shares_logo.png';
import "../css/main.css";
import { Grid } from '@mui/material';

export function ThankYouTwo() {

    return (
        <div>
            <Grid container>
                <div className="modal">
                    <div className="modal-content" style={{ padding: '20px' }}>
                        <div style={{ width: '100%', textAlign: 'center', padding: '20px 0px' }}>
                            <img src={logo} alt='company logo' />
                        </div>
                        <div style={{ width: '100%', textAlign: 'justify' }}>
                            <p>Your Embarc vacation membership documents have been forwarded to Vacation Ventures Group, LLC. Feel free to email them at <a style={{ color: 'blue' }} href="membercare@myepicvacations.com">membercare@myepicvacations.com</a>. Please include your contact information as well as mention of the fact you are a Sand Shares owner.</p>
                        </div>
                    </div>
                </div>
            </Grid>
        </div>
    )
}