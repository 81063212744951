import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoginPage } from './pages/LoginPage';
import { Form } from './pages/Form';
import { NewOffer } from './pages/NewOffer';
import { ThankYouOne } from './pages/ThankYouOne';
import { ThankYouTwo } from './pages/ThankYouTwo';
import { ThankYouThree } from './pages/ThankYouThree';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/offer/:contract/:record/:version" element={<NewOffer />} />
        <Route path="/form/:contract/:record" element={<Form />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/thank-you-one" element={<ThankYouOne />} />
        <Route path="/thank-you-two" element={<ThankYouTwo />} />
        <Route path="/thank-you-three" element={<ThankYouThree />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
