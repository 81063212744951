import { Grid } from "@material-ui/core";
import logo from '../images/sand_shares_logo.png';
import "../css/main.css";
import { useParams } from 'react-router-dom';


const startProcess = (contract: string | undefined, record: string | undefined) => {
    window.location.href = "/html/completeform.html?contract=" + contract + "&record=" + record;
}

export function NewOffer() {
    const params = useParams()

    return (
        <div>
            <Grid container>
                <div style={{
                    minHeight: '100vh',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    position: 'relative',
                    width: '100%',
                }}>
                </div>

                <div className="modal-large">
                    <div className="modal-content-large">
                        <div style={{ width: '100%', textAlign: 'center', padding: '20px 0px 50px 0px' }}>
                            <img src={logo} alt='company logo' />
                        </div>
                        <div>
                            <div style={{ backgroundColor: 'black', letterSpacing: '0.1em', color: 'white', textAlign: 'center', padding: '6px' }}>CHOOSE THE SAND SHARES OWNERSHIP OPTION THAT'S BEST FOR YOU</div>

                            <div style={{ fontSize: '1.1em', fontWeight: '500', marginTop: '30px', marginBottom: '20px', paddingBottom: '20px', width: 'max-content', borderBottom: '1px solid #ccc' }}>Deed Ownership back to the HOA</div>

                            <div>If your timeshare vacationing days have run their course, this is the easiest way out of your ownership. Simply sign the agreement and pay the $1,500 per week transfer fee and you are on your way. Our team will work with you and our attorneys to prepare your deed(s) for execution.  When the deed is recorded, you will be free of your timeshare and the obligations associated with it.</div>

                            <a href={"/html/completeform-1.html?contract=" + params.contract + "&record=" + params.record}><button style={{ padding: '10px', fontSize: '0.75em', fontWeight: 'bold', letterSpacing: '0.1em', border: '0px', color: 'white', backgroundColor: 'rgb(20, 74, 194)', marginTop: '20px', cursor: 'pointer' }}>TRANSFER OUT</button></a>

                            {params.version == 'v2' && <>

                                <div style={{ fontSize: '1.1em', fontWeight: '500', marginTop: '30px', marginBottom: '20px', paddingBottom: '20px', width: 'max-content', borderBottom: '1px solid #ccc' }}>
                                    Join Embarc and continue to vacation at a lower cost
                                </div>

                                <div>If you still love vacationing and would like to do so at a lower annual cost with no long-term obligation an Embarc vacations subscription is for you. With Embarc you enjoy member only pricing on hotels, resorts, cruises, airlines activities and more. Members also get VIP pricing on Hilton Head vacations. The best part is the ongoing fee is only $29 a month and you can cancel at any time.  The cost for Embarc membership AND the transferring back of your Sand Shares week is only $2,000 combined.</div>

                                <a href={"/html/completeform-2.html?contract=" + params.contract + "&record=" + params.record}><button style={{ padding: '10px', fontSize: '0.75em', fontWeight: 'bold', letterSpacing: '0.1em', border: '0px', color: 'white', backgroundColor: 'rgb(20, 74, 194)', marginTop: '20px', cursor: 'pointer' }}>JOIN EMBARC</button></a>

                                <div style={{ fontSize: '1.1em', fontWeight: '500', marginTop: '30px', marginBottom: '20px', paddingBottom: '20px', width: 'max-content', borderBottom: '1px solid #ccc' }}>Stay with Sand Shares</div>

                                <div>If you love Sand Shares and aren't ready to leave us, please click the button below.  You will be directed to sign a form informing us you are staying and paying the assessment.  Once all owners have responded and we have tallied everyone's wishes, we'll know how many and which of our 6 units we will be consolidating into. We'll then work with you to swap your ownership accordingly, if required.  The goal is to right size the resort to the number of interested owners and therefore stabilize the fees. Full disclosure, we won't be sure what the modified fees will moving forward be until the timeshare regime is right-sized. We will inform all remaining owners when the calculation is completed. We anticipate the new fee being due the first quarter of 2024.</div>

                                <a href={"/html/completeform-3.html?contract=" + params.contract + "&record=" + params.record}><button style={{ padding: '10px', fontSize: '0.75em', fontWeight: 'bold', letterSpacing: '0.1em', border: '0px', color: 'white', backgroundColor: 'rgb(20, 74, 194)', marginTop: '20px', marginBottom: '40px', cursor: 'pointer' }}>STAY WITH SAND SHARES</button></a>

                            </>}
                        </div>

                    </div>
                </div>
            </Grid>
        </div>
    )
}