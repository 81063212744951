import logo from '../images/sand_shares_logo.png';
import "../css/main.css";
import { Grid } from '@mui/material';

export function ThankYouThree() {

    return (
        <div>
            <Grid container>
                <div className="modal">
                <div className="modal-content" style={{ padding: '20px' }}>
                        <div style={{ width: '100%', textAlign: 'center', padding: '20px 0px' }}>
                            <img src={logo} alt='company logo' />
                        </div>
                        <div style={{ width: '100%', textAlign: 'justify' }}>
                            <p>Vacation Ownership Services will be back in touch with you when it becomes clear which of the remaining units owners will be transferred to.</p>
                        </div>
                    </div>
                </div>
            </Grid>
        </div>
    )
}