import logo from '../images/sand_shares_logo.png';
import "../css/main.css";
import { codes } from '../data/Codes'
import { Md5 } from "md5-typescript";
import { Grid } from '@mui/material';

const loginWithCode = async () => {
    const contractNumber = (document.getElementById('contractNumber') as HTMLInputElement).value;
    const recordLocator = (document.getElementById('recordLocator') as HTMLInputElement).value;
    const loginHash = Md5.init(contractNumber + recordLocator);
    console.log(loginHash);
    const codeIndex = codes.indexOf(loginHash);
    if (codeIndex != -1) {
        window.location.href = "/offer/" + contractNumber + "/" + recordLocator + "/" + codes[codeIndex + 1];
    } else {
        addErrorMessage('We could not locate your contract, please try again.');
    }
}

const removeErrorMessage = () => {
    let errorMessage = (document.getElementById('error-message') as HTMLInputElement);
    errorMessage.classList.add("hidden");
    errorMessage.innerHTML = '';
}

const addErrorMessage = (message: string) => {
    let errorMessage = (document.getElementById('error-message') as HTMLInputElement);
    errorMessage.classList.remove("hidden");
    errorMessage.innerText = message;
}

export function LoginPage() {

    return (
        <div>
            <Grid container>
                <div className="modal">
                    <div className="modal-content">
                        <div style={{ width: '100%', textAlign: 'center', padding: '20px 0px' }}>
                            <img src={logo} alt='company logo' />
                        </div>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <p>Please enter your contract number and record locator below:</p>
                        </div>
                        <div className="login-form">
                            <table cellSpacing="8">
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: 'right', position: 'relative', top: '0px' }}>Contract Number:</td>
                                        <td><input id="contractNumber" className="login-input" type="text" size={30} ></input></td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'right', position: 'relative', top: '0px' }}>Record Locator:</td>
                                        <td><input id="recordLocator" className="login-input" type="text" size={30} ></input></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <button id="login-button" onClick={loginWithCode} style={{ cursor: 'pointer', margin: '10px 0px 30px 0px', padding: '10px 30px', backgroundColor: '#22a845', border: '0px solid', borderRadius: '5px', color: 'white' }} >Login</button>
                        </div>
                        <div id="error-message" className="login-error hidden"></div>
                    </div>
                </div>
            </Grid>
        </div>
    )
}