import { Grid } from "@material-ui/core";
import logo from '../images/sand_shares_logo.png';
import "../css/main.css";
import { useParams } from 'react-router-dom';
import { useEffect } from "react";

export function Form() {
  const params = useParams()

  useEffect(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.src = "https://www.tfaforms.com/js/FA__DOMContentLoadedEventDispatcher.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);
  
  return (
    <div>
      <Grid container>
        <div style={{
          minHeight: '100vh',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          width: '100%',
        }}>
        </div>

        <div className="modal-large">
          <div className="modal-content-large">
          </div>
        </div>
      </Grid>
    </div>
  )
}